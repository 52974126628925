import { useEffect } from 'react';

const Ansvar = () => {
  useEffect(() => {
    window.location.replace(
      'https://corporate.rocker.com/sv/om-rocker/hallbarhet/',
    );
  }, []);

  return null;
};

export default Ansvar;
